import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import i18n from 'locales/i18next';

import { Order } from 'types/order';

import { getCurrencySymbol, getShortenedCryptoCurrencyCode } from 'utils/currencies';

import { LABELS } from './keys';
import {
  Amount,
  CardBrandCell,
  Cell,
  Grid,
  MinorText,
  Section,
  SectionContent,
  SectionContentItem,
  SectionSeparator,
  SectionTitle,
  SectionTitleCellLeft,
  SectionTitleCellRight,
  TotalAmount,
} from './styles';

export interface DetailsSectionProps {
  order?: Order;
  fiatAmount: number;
}

const DetailsSectionSell = ({ order, fiatAmount }: DetailsSectionProps) => {
  const { t } = useTranslation();
  const { totalLabel, processingFeeNotionalLabel, networkFeeNotionalLabel, assetCostNotionalLabel } = useMemo(() => {
    if (!order) {
      return {
        totalLabel: '',
        processingFeeNotionalLabel: '',
        networkFeeNotionalLabel: '',
        assetCostNotionalLabel: '',
      };
    }

    return {
      totalLabel: fiatAmount,
      processingFeeNotionalLabel: order?.processingFeeFiatAmount ?? '0',
      networkFeeNotionalLabel: order?.networkFeeFiatAmount ?? '0',
      assetCostNotionalLabel: order?.cryptoCostFiatAmount ?? '0',
    };
  }, [order]);

  if (!order) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>
        <SectionTitleCellLeft>{t(LABELS.DETAILS_TITLE)}</SectionTitleCellLeft>
        <SectionTitleCellRight>
          <Trans i18nKey="receipt.total" t={t}>
            {t(LABELS.TOTAL)}
            <TotalAmount>
              {getCurrencySymbol(i18n.language)(order.fiatCurrencyCode)}
              <TotalAmount>{totalLabel}</TotalAmount>
            </TotalAmount>
          </Trans>
        </SectionTitleCellRight>
      </SectionTitle>
      <SectionSeparator />
      <Grid>
        <Cell>
          {t(LABELS.CRYPTO)}{' '}
          <MinorText>
            {order.cryptoAmount} {getShortenedCryptoCurrencyCode(order?.cryptoCurrencyCode)}{' '}
          </MinorText>
        </Cell>
        <CardBrandCell>{getCurrencySymbol(i18n.language)(order.fiatCurrencyCode)}</CardBrandCell>
        <Amount>{assetCostNotionalLabel}</Amount>
        <Cell>{t(LABELS.PROCESSING_FEE)}</Cell>
        <CardBrandCell>{getCurrencySymbol(i18n.language)(order.fiatCurrencyCode)}</CardBrandCell>
        <Amount>{processingFeeNotionalLabel}</Amount>
      </Grid>
    </Section>
  );
};

export default DetailsSectionSell;
