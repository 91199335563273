import {
  APIKeysSectionRoute,
  AccountSettingsRoute,
  AccountsListRoute,
  GeneralSectionRoute,
  PaymentMethodsSectionRoute,
  UsersSectionRoute,
} from 'pages/account/route';
import { CustomerViewRoute, CustomersListRoute } from 'pages/customers/route';
import { DocumentationRoute } from 'pages/documentation/route';
import { OrderListRoute, OrderReceiptRoute, OrderViewRoute } from 'pages/orders/route';
import { ProfileRoute } from 'pages/profile/route';
import { ReconciliationsListRoute, ReconciliationsViewRoute } from 'pages/reconciliations/route';
import { SettingsRoute, UsersSectionRoute as SettingsUsersSectionRoute } from 'pages/settings/route';

import { RouteSetup } from 'types/routing';

import RoutePath from '../../path';
import { menuItems } from './menuItems';

export const SuperAdminRouterSetup: RouteSetup = {
  availableRoutes: [
    {
      path: RoutePath.Customers.Root,
      component: CustomersListRoute,
    },
    {
      path: RoutePath.Customers.ById,
      component: CustomerViewRoute,
    },
    {
      path: RoutePath.Orders.Root,
      component: OrderListRoute,
    },
    {
      path: RoutePath.Orders.ById,
      component: OrderViewRoute,
    },
    {
      path: RoutePath.Orders.ReceiptById,
      component: OrderReceiptRoute,
    },
    {
      path: RoutePath.Accounts.List,
      component: AccountsListRoute,
    },
    {
      path: RoutePath.GeneralSettings.Root,
      component: SettingsRoute,
      defaultRedirectRoute: RoutePath.GeneralSettings.Users,
      children: [
        {
          path: RoutePath.GeneralSettings.Users,
          component: SettingsUsersSectionRoute,
        },
        {
          path: RoutePath.GeneralSettings.Reconciliations,
          component: ReconciliationsListRoute,
        },
        {
          path: RoutePath.GeneralSettings.ReconciliationsById,
          component: ReconciliationsViewRoute,
        },
      ],
    },
    {
      path: RoutePath.AccountSettings.ById,
      component: AccountSettingsRoute,
      defaultRedirectRoute: RoutePath.AccountSettings.General,
      children: [
        {
          path: RoutePath.AccountSettings.General,
          component: GeneralSectionRoute,
        },
        {
          path: RoutePath.AccountSettings.PaymentMethods,
          component: PaymentMethodsSectionRoute,
        },
        {
          path: RoutePath.AccountSettings.APIKeys,
          component: APIKeysSectionRoute,
        },
        {
          path: RoutePath.AccountSettings.Users,
          component: UsersSectionRoute,
        },
      ],
    },
    {
      path: RoutePath.Documentation.Root,
      component: DocumentationRoute,
    },
    {
      path: RoutePath.Profile.Root,
      component: ProfileRoute,
    },
  ],
  menuItems,
  defaultLoginRedirectRoute: RoutePath.Orders.Root,
};

export default SuperAdminRouterSetup;
