export interface Customer {
  uuid: string;
  username: string;
  firstName: string;
  lastName: string;
  signupAt: string;
  shift4CustomerId: string;
  zHashParticipantCode: string;
  region: string;
  status: CustomerStatus;
  kycStatus: CustomerKYCStatus | null; // will be some enum
  kycReason: string | null;
  kycTimestamp: string | null;
  tosAcceptedAt: string | null;
  isKycRetryAllowed: boolean;
  kycAttemptsUsed: number | null;
  country: string; // country enum?
  state: string; // state enum?
  notifyWhenServiceAvailable: boolean;
  lastLogin: LastLoginInfo | null;
  agreedTermsOfService: boolean;
}

export interface LastLoginInfo {
  ipAddress: string | null;
  userAgent: string;
  ipCountry: string | null;
  ipState: string | null;
  loginAt: string;
  userUuid: string | null;
  accountUuid: string | null;
  //  partner account through which the customer last logged in
  account: {
    uuid: string;
    name: string;
  } | null;
}

export enum CustomerKYCStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  PendingApproval = 'pending_approval',
  Submitted = 'submitted',
  Null = 'null',
}

export enum CustomerStatus {
  Active = 'active',
  Blocked = 'blocked',
  Terminated = 'terminated',
}

export enum CustomerTerminationReason {
  ComplianceIssue = 'compliance_issue',
  UserRequest = 'user_request',
}

export enum CardBrand {
  Visa = 'Visa',
  Amex = 'American Express',
  MasterCard = 'MasterCard',
  Discover = 'Discover',
  Jcb = 'JCB',
  DinersClub = 'Diners Club',
  Unknown = 'Unknown',
  Maestro = 'Maestro',
}

export interface CustomerCard {
  id: string;
  last4: string;
  expMonth: string;
  expYear: string;
  brand: CardBrand;
  type: string;
  isDefault: boolean;
  isExpired: boolean;
}
