import { UrlParam } from '../types/base';

export const routes = {
  Root: '/',
  Login: '/login',
  Register: '/register',
  GeneralSettings: {
    Root: '/general-settings',
    Users: 'users',
    Reconciliations: '/general-settings/settlement-reconciliations',
    ReconciliationsById: '/general-settings/settlement-reconciliations/:uuid',
  },
  AccountSettings: {
    Root: '/account',
    ById: '/account/:uuid',
    General: 'general',
    PaymentMethods: 'payment-methods',
    APIKeys: 'api-keys',
    Users: 'users',
  },
  AcceptInvite: '/invite/accept',
  Orders: {
    Root: '/orders',
    ById: '/orders/:uuid',
    ReceiptById: '/orders/:uuid/receipt',
  },
  Customers: {
    Root: '/customers',
    ById: '/customers/:uuid',
  },
  Accounts: {
    List: '/accounts',
  },
  Documentation: {
    Root: '/documentation',
  },
  Profile: {
    Root: '/profile',
  },
};

export const RoutePath = {
  ...routes,
  accountById: (uuid: UrlParam) => [RoutePath.AccountSettings.Root, uuid].join('/'),
  orderById: (uuid: UrlParam) => [RoutePath.Orders.Root, uuid].join('/'),
  orderReceiptById: (uuid: UrlParam) => [RoutePath.Orders.Root, uuid, 'receipt'].join('/'),
  customerById: (uuid: UrlParam) => RoutePath.Customers.ById.replace(':uuid', uuid.toString()),
  ReconciliationsById: (uuid: UrlParam) => [RoutePath.GeneralSettings.Reconciliations, uuid].join('/'),
};

export default RoutePath;
